/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingsDomain
 */
export interface SettingsDomain {
    /**
     * 
     * @type {string}
     * @memberof SettingsDomain
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDomain
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingsDomain
     */
    type?: SettingsDomainTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SettingsDomain
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDomain
     */
    trackGA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SettingsDomain
     */
    trackConversion?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SettingsDomain
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SettingsDomain
     */
    properties?: { [key: string]: string; };
}


/**
 * @export
 */
export const SettingsDomainTypeEnum = {
    Free: 'FREE',
    Pro: 'PRO',
    Starter: 'STARTER'
} as const;
export type SettingsDomainTypeEnum = typeof SettingsDomainTypeEnum[keyof typeof SettingsDomainTypeEnum];


/**
 * Check if a given object implements the SettingsDomain interface.
 */
export function instanceOfSettingsDomain(value: object): boolean {
    return true;
}

export function SettingsDomainFromJSON(json: any): SettingsDomain {
    return SettingsDomainFromJSONTyped(json, false);
}

export function SettingsDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsDomain {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'type': json['type'] == null ? undefined : json['type'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'trackGA': json['trackGA'] == null ? undefined : json['trackGA'],
        'trackConversion': json['trackConversion'] == null ? undefined : json['trackConversion'],
        'aliases': json['aliases'] == null ? undefined : json['aliases'],
        'properties': json['properties'] == null ? undefined : json['properties'],
    };
}

export function SettingsDomainToJSON(value?: SettingsDomain | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': value['type'],
        'timezone': value['timezone'],
        'trackGA': value['trackGA'],
        'trackConversion': value['trackConversion'],
        'aliases': value['aliases'],
        'properties': value['properties'],
    };
}

