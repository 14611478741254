/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WordPressSignupCreateRequest
 */
export interface WordPressSignupCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof WordPressSignupCreateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WordPressSignupCreateRequest
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof WordPressSignupCreateRequest
     */
    adminUrl: string;
    /**
     * 
     * @type {string}
     * @memberof WordPressSignupCreateRequest
     */
    version: string;
}

/**
 * Check if a given object implements the WordPressSignupCreateRequest interface.
 */
export function instanceOfWordPressSignupCreateRequest(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('domain' in value)) return false;
    if (!('adminUrl' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function WordPressSignupCreateRequestFromJSON(json: any): WordPressSignupCreateRequest {
    return WordPressSignupCreateRequestFromJSONTyped(json, false);
}

export function WordPressSignupCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordPressSignupCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'domain': json['domain'],
        'adminUrl': json['adminUrl'],
        'version': json['version'],
    };
}

export function WordPressSignupCreateRequestToJSON(value?: WordPressSignupCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'domain': value['domain'],
        'adminUrl': value['adminUrl'],
        'version': value['version'],
    };
}

