/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActionSchedule } from './ActionSchedule';
import {
    ActionScheduleFromJSON,
    ActionScheduleFromJSONTyped,
    ActionScheduleToJSON,
} from './ActionSchedule';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Action
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    actionType: ActionActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    actionValue?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Action
     */
    properties: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    backgroundColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Action
     */
    iconEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    iconClass?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    iconColor?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    iconText?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    iconType?: ActionIconTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    iconBackgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    labelBackgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    labelText?: string;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof Action
     */
    schedule?: ActionSchedule;
}


/**
 * @export
 */
export const ActionActionTypeEnum = {
    Anchor: 'ANCHOR',
    Email: 'EMAIL',
    Hours: 'HOURS',
    Link: 'LINK',
    Map: 'MAP',
    Phone: 'PHONE',
    Sms: 'SMS',
    Whatsapp: 'WHATSAPP',
    Facebook: 'FACEBOOK',
    Signal: 'SIGNAL',
    Telegram: 'TELEGRAM',
    Iframe: 'IFRAME',
    Tally: 'TALLY',
    Intercom: 'INTERCOM',
    Skype: 'SKYPE',
    Zalo: 'ZALO',
    Viber: 'VIBER',
    Line: 'LINE',
    Wechat: 'WECHAT',
    Chat: 'CHAT'
} as const;
export type ActionActionTypeEnum = typeof ActionActionTypeEnum[keyof typeof ActionActionTypeEnum];

/**
 * @export
 */
export const ActionIconTypeEnum = {
    Default: 'DEFAULT',
    Custom: 'CUSTOM',
    Font: 'FONT',
    Svg: 'SVG',
    FontMaterial: 'FONT_MATERIAL'
} as const;
export type ActionIconTypeEnum = typeof ActionIconTypeEnum[keyof typeof ActionIconTypeEnum];


/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('user' in value)) return false;
    if (!('actionType' in value)) return false;
    if (!('properties' in value)) return false;
    return true;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'actionType': json['actionType'],
        'actionValue': json['actionValue'] == null ? undefined : json['actionValue'],
        'properties': json['properties'],
        'backgroundColor': json['backgroundColor'] == null ? undefined : json['backgroundColor'],
        'iconEnabled': json['iconEnabled'] == null ? undefined : json['iconEnabled'],
        'iconClass': json['iconClass'] == null ? undefined : json['iconClass'],
        'iconColor': json['iconColor'] == null ? undefined : json['iconColor'],
        'iconText': json['iconText'] == null ? undefined : json['iconText'],
        'iconType': json['iconType'] == null ? undefined : json['iconType'],
        'iconBackgroundImage': json['iconBackgroundImage'] == null ? undefined : json['iconBackgroundImage'],
        'labelBackgroundColor': json['labelBackgroundColor'] == null ? undefined : json['labelBackgroundColor'],
        'labelText': json['labelText'] == null ? undefined : json['labelText'],
        'schedule': json['schedule'] == null ? undefined : ActionScheduleFromJSON(json['schedule']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user': UserToJSON(value['user']),
        'actionType': value['actionType'],
        'actionValue': value['actionValue'],
        'properties': value['properties'],
        'backgroundColor': value['backgroundColor'],
        'iconEnabled': value['iconEnabled'],
        'iconClass': value['iconClass'],
        'iconColor': value['iconColor'],
        'iconText': value['iconText'],
        'iconType': value['iconType'],
        'iconBackgroundImage': value['iconBackgroundImage'],
        'labelBackgroundColor': value['labelBackgroundColor'],
        'labelText': value['labelText'],
        'schedule': ActionScheduleToJSON(value['schedule']),
    };
}

