/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CnbMarketingData } from './CnbMarketingData';
import {
    CnbMarketingDataFromJSON,
    CnbMarketingDataFromJSONTyped,
    CnbMarketingDataToJSON,
} from './CnbMarketingData';
import type { StripeDetails } from './StripeDetails';
import {
    StripeDetailsFromJSON,
    StripeDetailsFromJSONTyped,
    StripeDetailsToJSON,
} from './StripeDetails';
import type { UserSettings } from './UserSettings';
import {
    UserSettingsFromJSON,
    UserSettingsFromJSONTyped,
    UserSettingsToJSON,
} from './UserSettings';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {StripeDetails}
     * @memberof User
     */
    stripeDetails?: StripeDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles: Array<string>;
    /**
     * 
     * @type {CnbMarketingData}
     * @memberof User
     */
    marketingData?: CnbMarketingData;
    /**
     * 
     * @type {Array<object>}
     * @memberof User
     */
    taxIds?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    address?: object;
    /**
     * 
     * @type {UserSettings}
     * @memberof User
     */
    settings?: UserSettings;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('active' in value)) return false;
    if (!('email' in value)) return false;
    if (!('roles' in value)) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'],
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'stripeDetails': json['stripeDetails'] == null ? undefined : StripeDetailsFromJSON(json['stripeDetails']),
        'roles': json['roles'],
        'marketingData': json['marketingData'] == null ? undefined : CnbMarketingDataFromJSON(json['marketingData']),
        'taxIds': json['taxIds'] == null ? undefined : json['taxIds'],
        'address': json['address'] == null ? undefined : json['address'],
        'settings': json['settings'] == null ? undefined : UserSettingsFromJSON(json['settings']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'active': value['active'],
        'name': value['name'],
        'email': value['email'],
        'companyName': value['companyName'],
        'phone': value['phone'],
        'stripeDetails': StripeDetailsToJSON(value['stripeDetails']),
        'roles': value['roles'],
        'marketingData': CnbMarketingDataToJSON(value['marketingData']),
        'taxIds': value['taxIds'],
        'address': value['address'],
        'settings': UserSettingsToJSON(value['settings']),
    };
}

