import { createTheme } from "@mui/material/styles";

import { blueGrey, grey } from "@mui/material/colors";

export default createTheme({
  palette: {
    primary: { main: "#00aa00" },
    secondary: { main: blueGrey[900] },
    text: {
      secondary: "rgba(0, 0, 0, 0.4)",
    },
    warning: { main: "#FFC260" },
    info: { main: "#9013FE" },
    success: { main: "#3CD4A0" },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "@media (min-width:600px)": {
            backgroundImage: "url(https://source.unsplash.com/1280x720?nature)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
          },
          "@media (min-width:1920px)": {
            backgroundImage:
              "url(https://source.unsplash.com/1920x1080?nature)",
          },
          "@media print": {
            backgroundImage: "none",
          },
        },
        pre: {
          padding: 8,
          backgroundColor: grey[200],
          overflow: "auto",
          fontSize: 14,
          whiteSpace: "normal",
          wordBreak: "break-all",
        },
        code: {
          whiteSpace: "pre",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          textTransform: "capitalize",
          alignItems: "flex-start",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.7)",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        button: {
          "&:disabled": {
            color: "rgba(0, 0, 0, 0.26)",
            "&:hover": {
              textDecoration: "none",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "16px 0",
          border: "1px solid rgba(0, 0, 0, .125)",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
        },
        rounded: {
          borderRadius: 4,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "auto",
          },
        },
        content: {
          alignItems: "center",
          "&.Mui-expanded": {
            margin: "12px 0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
    },
  },
});
