/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordResetResponse
 */
export interface PasswordResetResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordResetResponse
     */
    success: boolean;
}

/**
 * Check if a given object implements the PasswordResetResponse interface.
 */
export function instanceOfPasswordResetResponse(value: object): boolean {
    if (!('success' in value)) return false;
    return true;
}

export function PasswordResetResponseFromJSON(json: any): PasswordResetResponse {
    return PasswordResetResponseFromJSONTyped(json, false);
}

export function PasswordResetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function PasswordResetResponseToJSON(value?: PasswordResetResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
    };
}

