/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Validation } from './Validation';
import {
    ValidationFromJSON,
    ValidationFromJSONTyped,
    ValidationToJSON,
} from './Validation';

/**
 * 
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    id?: string;
    /**
     * 
     * @type {Array<Validation>}
     * @memberof ValidationResult
     */
    messages: Array<Validation>;
}

/**
 * Check if a given object implements the ValidationResult interface.
 */
export function instanceOfValidationResult(value: object): boolean {
    if (!('messages' in value)) return false;
    return true;
}

export function ValidationResultFromJSON(json: any): ValidationResult {
    return ValidationResultFromJSONTyped(json, false);
}

export function ValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'messages': ((json['messages'] as Array<any>).map(ValidationFromJSON)),
    };
}

export function ValidationResultToJSON(value?: ValidationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'messages': ((value['messages'] as Array<any>).map(ValidationToJSON)),
    };
}

