/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConditionUpdateRequest
 */
export interface ConditionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConditionUpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionUpdateRequest
     */
    conditionType?: ConditionUpdateRequestConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionUpdateRequest
     */
    filterType?: ConditionUpdateRequestFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionUpdateRequest
     */
    matchType?: ConditionUpdateRequestMatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionUpdateRequest
     */
    matchValue?: string;
}


/**
 * @export
 */
export const ConditionUpdateRequestConditionTypeEnum = {
    Url: 'URL',
    Geo: 'GEO'
} as const;
export type ConditionUpdateRequestConditionTypeEnum = typeof ConditionUpdateRequestConditionTypeEnum[keyof typeof ConditionUpdateRequestConditionTypeEnum];

/**
 * @export
 */
export const ConditionUpdateRequestFilterTypeEnum = {
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;
export type ConditionUpdateRequestFilterTypeEnum = typeof ConditionUpdateRequestFilterTypeEnum[keyof typeof ConditionUpdateRequestFilterTypeEnum];

/**
 * @export
 */
export const ConditionUpdateRequestMatchTypeEnum = {
    Simple: 'SIMPLE',
    Exact: 'EXACT',
    Regex: 'REGEX',
    Substring: 'SUBSTRING',
    CountryCode: 'COUNTRY_CODE'
} as const;
export type ConditionUpdateRequestMatchTypeEnum = typeof ConditionUpdateRequestMatchTypeEnum[keyof typeof ConditionUpdateRequestMatchTypeEnum];


/**
 * Check if a given object implements the ConditionUpdateRequest interface.
 */
export function instanceOfConditionUpdateRequest(value: object): boolean {
    if (!('id' in value)) return false;
    return true;
}

export function ConditionUpdateRequestFromJSON(json: any): ConditionUpdateRequest {
    return ConditionUpdateRequestFromJSONTyped(json, false);
}

export function ConditionUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'conditionType': json['conditionType'] == null ? undefined : json['conditionType'],
        'filterType': json['filterType'] == null ? undefined : json['filterType'],
        'matchType': json['matchType'] == null ? undefined : json['matchType'],
        'matchValue': json['matchValue'] == null ? undefined : json['matchValue'],
    };
}

export function ConditionUpdateRequestToJSON(value?: ConditionUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'conditionType': value['conditionType'],
        'filterType': value['filterType'],
        'matchType': value['matchType'],
        'matchValue': value['matchValue'],
    };
}

