/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScrollOptions } from './ScrollOptions';
import {
    ScrollOptionsFromJSON,
    ScrollOptionsFromJSONTyped,
    ScrollOptionsToJSON,
} from './ScrollOptions';

/**
 * 
 * @export
 * @interface ButtonOptions
 */
export interface ButtonOptions {
    /**
     * 
     * @type {string}
     * @memberof ButtonOptions
     */
    placement: ButtonOptionsPlacementEnum;
    /**
     * 
     * @type {string}
     * @memberof ButtonOptions
     */
    position?: ButtonOptionsPositionEnum;
    /**
     * 
     * @type {string}
     * @memberof ButtonOptions
     */
    displayMode?: ButtonOptionsDisplayModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ButtonOptions
     */
    animation?: ButtonOptionsAnimationEnum;
    /**
     * 
     * @type {ScrollOptions}
     * @memberof ButtonOptions
     */
    scroll?: ScrollOptions;
    /**
     * 
     * @type {string}
     * @memberof ButtonOptions
     */
    cssClasses?: string;
}


/**
 * @export
 */
export const ButtonOptionsPlacementEnum = {
    Default: 'DEFAULT',
    BottomLeft: 'BOTTOM_LEFT',
    BottomCenter: 'BOTTOM_CENTER',
    BottomRight: 'BOTTOM_RIGHT',
    MiddleLeft: 'MIDDLE_LEFT',
    MiddleRight: 'MIDDLE_RIGHT',
    TopLeft: 'TOP_LEFT',
    TopCenter: 'TOP_CENTER',
    TopRight: 'TOP_RIGHT'
} as const;
export type ButtonOptionsPlacementEnum = typeof ButtonOptionsPlacementEnum[keyof typeof ButtonOptionsPlacementEnum];

/**
 * @export
 */
export const ButtonOptionsPositionEnum = {
    Default: 'DEFAULT',
    Fixed: 'FIXED',
    Absolute: 'ABSOLUTE'
} as const;
export type ButtonOptionsPositionEnum = typeof ButtonOptionsPositionEnum[keyof typeof ButtonOptionsPositionEnum];

/**
 * @export
 */
export const ButtonOptionsDisplayModeEnum = {
    Always: 'ALWAYS',
    MobileOnly: 'MOBILE_ONLY',
    DesktopOnly: 'DESKTOP_ONLY'
} as const;
export type ButtonOptionsDisplayModeEnum = typeof ButtonOptionsDisplayModeEnum[keyof typeof ButtonOptionsDisplayModeEnum];

/**
 * @export
 */
export const ButtonOptionsAnimationEnum = {
    None: 'NONE',
    Shake: 'SHAKE',
    Tada: 'TADA',
    SonarLight: 'SONAR_LIGHT',
    SonarDark: 'SONAR_DARK'
} as const;
export type ButtonOptionsAnimationEnum = typeof ButtonOptionsAnimationEnum[keyof typeof ButtonOptionsAnimationEnum];


/**
 * Check if a given object implements the ButtonOptions interface.
 */
export function instanceOfButtonOptions(value: object): boolean {
    if (!('placement' in value)) return false;
    return true;
}

export function ButtonOptionsFromJSON(json: any): ButtonOptions {
    return ButtonOptionsFromJSONTyped(json, false);
}

export function ButtonOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ButtonOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'placement': json['placement'],
        'position': json['position'] == null ? undefined : json['position'],
        'displayMode': json['displayMode'] == null ? undefined : json['displayMode'],
        'animation': json['animation'] == null ? undefined : json['animation'],
        'scroll': json['scroll'] == null ? undefined : ScrollOptionsFromJSON(json['scroll']),
        'cssClasses': json['cssClasses'] == null ? undefined : json['cssClasses'],
    };
}

export function ButtonOptionsToJSON(value?: ButtonOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'placement': value['placement'],
        'position': value['position'],
        'displayMode': value['displayMode'],
        'animation': value['animation'],
        'scroll': ScrollOptionsToJSON(value['scroll']),
        'cssClasses': value['cssClasses'],
    };
}

