/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConditionCreateRequest
 */
export interface ConditionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConditionCreateRequest
     */
    conditionType: ConditionCreateRequestConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionCreateRequest
     */
    filterType: ConditionCreateRequestFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionCreateRequest
     */
    matchType: ConditionCreateRequestMatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionCreateRequest
     */
    matchValue: string;
}


/**
 * @export
 */
export const ConditionCreateRequestConditionTypeEnum = {
    Url: 'URL',
    Geo: 'GEO'
} as const;
export type ConditionCreateRequestConditionTypeEnum = typeof ConditionCreateRequestConditionTypeEnum[keyof typeof ConditionCreateRequestConditionTypeEnum];

/**
 * @export
 */
export const ConditionCreateRequestFilterTypeEnum = {
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;
export type ConditionCreateRequestFilterTypeEnum = typeof ConditionCreateRequestFilterTypeEnum[keyof typeof ConditionCreateRequestFilterTypeEnum];

/**
 * @export
 */
export const ConditionCreateRequestMatchTypeEnum = {
    Simple: 'SIMPLE',
    Exact: 'EXACT',
    Regex: 'REGEX',
    Substring: 'SUBSTRING',
    CountryCode: 'COUNTRY_CODE'
} as const;
export type ConditionCreateRequestMatchTypeEnum = typeof ConditionCreateRequestMatchTypeEnum[keyof typeof ConditionCreateRequestMatchTypeEnum];


/**
 * Check if a given object implements the ConditionCreateRequest interface.
 */
export function instanceOfConditionCreateRequest(value: object): boolean {
    if (!('conditionType' in value)) return false;
    if (!('filterType' in value)) return false;
    if (!('matchType' in value)) return false;
    if (!('matchValue' in value)) return false;
    return true;
}

export function ConditionCreateRequestFromJSON(json: any): ConditionCreateRequest {
    return ConditionCreateRequestFromJSONTyped(json, false);
}

export function ConditionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'conditionType': json['conditionType'],
        'filterType': json['filterType'],
        'matchType': json['matchType'],
        'matchValue': json['matchValue'],
    };
}

export function ConditionCreateRequestToJSON(value?: ConditionCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conditionType': value['conditionType'],
        'filterType': value['filterType'],
        'matchType': value['matchType'],
        'matchValue': value['matchValue'],
    };
}

