/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    phone?: string;
}

/**
 * Check if a given object implements the UserUpdateRequest interface.
 */
export function instanceOfUserUpdateRequest(value: object): boolean {
    return true;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
    return UserUpdateRequestFromJSONTyped(json, false);
}

export function UserUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'phone': json['phone'] == null ? undefined : json['phone'],
    };
}

export function UserUpdateRequestToJSON(value?: UserUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'companyName': value['companyName'],
        'phone': value['phone'],
    };
}

