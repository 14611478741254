/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    principal: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Auth
     */
    authorities: Array<string>;
}

/**
 * Check if a given object implements the Auth interface.
 */
export function instanceOfAuth(value: object): boolean {
    if (!('principal' in value)) return false;
    if (!('authorities' in value)) return false;
    return true;
}

export function AuthFromJSON(json: any): Auth {
    return AuthFromJSONTyped(json, false);
}

export function AuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): Auth {
    if (json == null) {
        return json;
    }
    return {
        
        'principal': json['principal'],
        'authorities': json['authorities'],
    };
}

export function AuthToJSON(value?: Auth | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'principal': value['principal'],
        'authorities': value['authorities'],
    };
}

