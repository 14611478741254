/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionStatusData
 */
export interface SubscriptionStatusData {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusData
     */
    domainId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStatusData
     */
    existingSubscriptions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStatusData
     */
    activeTrial: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionStatusData
     */
    activeSubscription: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusData
     */
    invoiceUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionStatusData
     */
    invoiceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusData
     */
    invoiceCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionStatusData
     */
    interval?: SubscriptionStatusDataIntervalEnum;
}


/**
 * @export
 */
export const SubscriptionStatusDataIntervalEnum = {
    Monthly: 'monthly',
    Yearly: 'yearly'
} as const;
export type SubscriptionStatusDataIntervalEnum = typeof SubscriptionStatusDataIntervalEnum[keyof typeof SubscriptionStatusDataIntervalEnum];


/**
 * Check if a given object implements the SubscriptionStatusData interface.
 */
export function instanceOfSubscriptionStatusData(value: object): boolean {
    if (!('existingSubscriptions' in value)) return false;
    if (!('activeTrial' in value)) return false;
    if (!('activeSubscription' in value)) return false;
    return true;
}

export function SubscriptionStatusDataFromJSON(json: any): SubscriptionStatusData {
    return SubscriptionStatusDataFromJSONTyped(json, false);
}

export function SubscriptionStatusDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatusData {
    if (json == null) {
        return json;
    }
    return {
        
        'domainId': json['domainId'] == null ? undefined : json['domainId'],
        'existingSubscriptions': json['existingSubscriptions'],
        'activeTrial': json['activeTrial'],
        'activeSubscription': json['activeSubscription'],
        'invoiceUrl': json['invoiceUrl'] == null ? undefined : json['invoiceUrl'],
        'invoiceAmount': json['invoiceAmount'] == null ? undefined : json['invoiceAmount'],
        'invoiceCurrency': json['invoiceCurrency'] == null ? undefined : json['invoiceCurrency'],
        'interval': json['interval'] == null ? undefined : json['interval'],
    };
}

export function SubscriptionStatusDataToJSON(value?: SubscriptionStatusData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'domainId': value['domainId'],
        'existingSubscriptions': value['existingSubscriptions'],
        'activeTrial': value['activeTrial'],
        'activeSubscription': value['activeSubscription'],
        'invoiceUrl': value['invoiceUrl'],
        'invoiceAmount': value['invoiceAmount'],
        'invoiceCurrency': value['invoiceCurrency'],
        'interval': value['interval'],
    };
}

