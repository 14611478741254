/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Apikey } from './Apikey';
import {
    ApikeyFromJSON,
    ApikeyFromJSONTyped,
    ApikeyToJSON,
} from './Apikey';

/**
 * 
 * @export
 * @interface ApikeyDeleteResponse
 */
export interface ApikeyDeleteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApikeyDeleteResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApikeyDeleteResponse
     */
    id: string;
    /**
     * 
     * @type {Apikey}
     * @memberof ApikeyDeleteResponse
     */
    object: Apikey;
}

/**
 * Check if a given object implements the ApikeyDeleteResponse interface.
 */
export function instanceOfApikeyDeleteResponse(value: object): boolean {
    if (!('success' in value)) return false;
    if (!('id' in value)) return false;
    if (!('object' in value)) return false;
    return true;
}

export function ApikeyDeleteResponseFromJSON(json: any): ApikeyDeleteResponse {
    return ApikeyDeleteResponseFromJSONTyped(json, false);
}

export function ApikeyDeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApikeyDeleteResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'id': json['id'],
        'object': ApikeyFromJSON(json['object']),
    };
}

export function ApikeyDeleteResponseToJSON(value?: ApikeyDeleteResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'id': value['id'],
        'object': ApikeyToJSON(value['object']),
    };
}

