import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import {
  routes,
  FormActions,
  RouteDrawer,
  SpinnerContext,
  useExtendedForm,
} from "../common";
import { DomainContext } from "../domain";
import ActionSettings, { actionSchema } from "./ActionSettings";
import { ActionType, ButtonType } from "./buttonModels";
import {
  ActionList,
  ButtonPresentation,
  ButtonPositionField,
  DeleteButton,
  Scheduling,
  Visibility,
  ActionProps,
} from "./components";
import { ButtonContext } from "./useButton";
import { ButtonTypeRestrictions } from "./DomainRestrictions";
import AnimationChoice from "./components/AnimationChoice";
import ButtonPreview from "../common/components/ButtonPreview";
import { Action, Button, Domain } from "../lib/cnb/api/public";

const schema = yup
  .object<Button>()
  .shape({
    name: yup.string().required("give your button a name"),
    type: yup.mixed().required() as yup.MixedSchema<Button["type"]>,
    options: yup.object<Button["options"]>().defined(),
    actions: yup
      .array<Action>()
      .of(actionSchema)
      .when("type", (type: Button["type"], s: yup.MixedSchema) =>
        type === "SINGLE" ? s.required() : s.notRequired(),
      )
      .defined(),
  })
  .defined();

const ButtonSettings: React.FC = () => {
  const { bid } = useParams<{ bid: string }>();
  const history = useHistory();
  const { buttons, createButton, updateButton, deleteButton } =
    React.useContext(ButtonContext);
  const { currentDomain } = React.useContext(DomainContext);
  const { withSpinner } = React.useContext(SpinnerContext);
  const defaultButton = buttons?.[bid] || ButtonType.INITIAL;
  const formal = useExtendedForm<Button>({
    initialValues: {
      ...defaultButton,
      domain: currentDomain,
      options: {
        scroll: ButtonType.INITIAL.options?.scroll,
        ...defaultButton.options,
      },
    } as Button,
    schema,
    onSubmit: async (values) => {
      const button = {
        ...values,
        conditions: values.conditions.map((c) => ({
          ...c,
          conditionType:
            c.matchType === "COUNTRY_CODE" ? "GEO" : c.conditionType,
        })),
      };
      await withSpinner(
        values.id ? updateButton(button) : createButton(button),
      );
      history.goBack();
    },
    onReset: () => history.goBack(),
  });
  useEffect(() => {
    if (formal.values.type === "SINGLE") {
      // NOTE: This also runs on first render of a new button,
      //  when the default action list is empty.
      formal.change("actions", [
        formal.values.actions.find((a) => a) || {
          ...ActionType.INITIAL,
          schedule: {
            ...ActionType.INITIAL.schedule,
            timezone: currentDomain?.timezone,
          },
          iconEnabled: true,
        },
      ]);
    } else if (formal.isDirty) {
      formal.change("actions", []);
    }
    if (
      formal.values.type !== "FULL" &&
      formal.values.options.placement === "TOP_CENTER"
    ) {
      formal.change("options", {
        ...formal.values.options,
        placement: "BOTTOM_RIGHT",
      });
    }
    if (formal.values.type === "MULTI" && !formal.values.multiButtonOptions) {
      formal.change(
        "multiButtonOptions",
        ButtonType.INITIAL.multiButtonOptions,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formal.values.type]);

  const handleDelete = async () => {
    await withSpinner(deleteButton(formal.values.id!));
    history.goBack();
  };
  const showSingle =
    formal.values.type === "SINGLE" && !!formal.values.actions.length;
  const showNotSingle = formal.values.type !== "SINGLE";
  const showFull = formal.values.type === "FULL";
  const showDots = formal.values.type === "DOTS";
  const showMulti = formal.values.type === "MULTI";
  const isFlower =
    showMulti && formal.values.options.cssClasses?.includes("cnb-multi-flower");
  const disallowAddAction =
    currentDomain?.type === "STARTER" &&
    showFull &&
    formal.values.actions.length > 0;

  const isTypeDisabled = (type: Button["type"]) =>
    !ButtonTypeRestrictions[type].includes(
      currentDomain?.type as Domain["type"],
    );

  return (
    <>
      <form {...formal.getFormProps()}>
        <FormGroup>
          <TextField
            label="Button name"
            {...formal.getTextFieldProps("name")}
          />
          <TextField
            label="Button type"
            select
            SelectProps={{
              native: true,
            }}
            {...formal.getTextFieldProps("type")}
          >
            {Object.entries(ButtonType.TYPE_NAME).map(([type, name]) => (
              <option
                key={type}
                value={type}
                disabled={isTypeDisabled(type as Button["type"])}
              >
                {name}{" "}
                {(type as Button["type"]) === "MULTI" && isFlower && "flower"}
                {isTypeDisabled(type as Button["type"]) && " (PRO)"}
              </option>
            ))}
          </TextField>
          {showFull && <ButtonPositionField formal={formal} />}
          {showDots && <ButtonPositionField formal={formal} />}
          {showSingle && (
            <>
              <ActionProps formal={formal} pathPrefix="actions[0]." />
              <ButtonPresentation formal={formal} />
              <Scheduling formal={formal} pathPrefix="actions[0]." />
            </>
          )}
          {showMulti && <ButtonPresentation formal={formal} />}
          <Visibility formal={formal} />
          <AnimationChoice formal={formal} />
          {showNotSingle && (
            <ActionList formal={formal} disallowAddAction={disallowAddAction} />
          )}
          <FormActions formal={formal} />
          {formal.submitState.state === "error" && (
            <FormHelperText error>
              {formal.submitState.message || "Could not create a new button."}
            </FormHelperText>
          )}
          {!!formal.values.id && (
            <DeleteButton targetName="button" onClick={handleDelete} />
          )}
        </FormGroup>
      </form>
      <RouteDrawer {...routes.ACTION_NEW} drawerProps={{ anchor: "left" }}>
        <ActionSettings buttonFormal={formal} />
      </RouteDrawer>
      <RouteDrawer {...routes.ACTION_EDIT} drawerProps={{ anchor: "left" }}>
        <ActionSettings buttonFormal={formal} />
      </RouteDrawer>
      <ButtonPreview formal={formal} />
    </>
  );
};

export default ButtonSettings;
