/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutSessionResponse
 */
export interface CheckoutSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponse
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponse
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponse
     */
    checkoutSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponse
     */
    subscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSessionResponse
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSessionResponse
     */
    trialPeriodDays: number;
}

/**
 * Check if a given object implements the CheckoutSessionResponse interface.
 */
export function instanceOfCheckoutSessionResponse(value: object): boolean {
    if (!('user' in value)) return false;
    if (!('domain' in value)) return false;
    if (!('checkoutSessionId' in value)) return false;
    if (!('subscriptionId' in value)) return false;
    if (!('url' in value)) return false;
    if (!('trialPeriodDays' in value)) return false;
    return true;
}

export function CheckoutSessionResponseFromJSON(json: any): CheckoutSessionResponse {
    return CheckoutSessionResponseFromJSONTyped(json, false);
}

export function CheckoutSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutSessionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'user': json['user'],
        'domain': json['domain'],
        'checkoutSessionId': json['checkoutSessionId'],
        'subscriptionId': json['subscriptionId'],
        'url': json['url'],
        'trialPeriodDays': json['trialPeriodDays'],
    };
}

export function CheckoutSessionResponseToJSON(value?: CheckoutSessionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user': value['user'],
        'domain': value['domain'],
        'checkoutSessionId': value['checkoutSessionId'],
        'subscriptionId': value['subscriptionId'],
        'url': value['url'],
        'trialPeriodDays': value['trialPeriodDays'],
    };
}

