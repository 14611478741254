/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Settings,
} from '../models/index';
import {
    SettingsFromJSON,
    SettingsToJSON,
} from '../models/index';

export interface SettingsApiGetSettingsForUserIdRequest {
    userId: string;
}

export interface SettingsApiGetSettingsForUserIdAndDomainIdRequest {
    userId: string;
    domainId: string;
}

export interface SettingsApiGetSettingsForUserIdAndDomainIdJsRequest {
    userId: string;
    domainId: string;
}

export interface SettingsApiGetSettingsForUserIdJsRequest {
    userId: string;
}

export interface SettingsApiGetSettingsJsonForDomainRequest {
    userId: string;
    domainId: string;
}

export interface SettingsApiGetSettingsJsonForUserRequest {
    userId: string;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Get the JS Snippet for all domains of this User.
     */
    async getSettingsForUserIdRaw(requestParameters: SettingsApiGetSettingsForUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsForUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the JS Snippet for all domains of this User.
     */
    async getSettingsForUserId(requestParameters: SettingsApiGetSettingsForUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSettingsForUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the JS Snippet for this Domain.
     */
    async getSettingsForUserIdAndDomainIdRaw(requestParameters: SettingsApiGetSettingsForUserIdAndDomainIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsForUserIdAndDomainId().'
            );
        }

        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getSettingsForUserIdAndDomainId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}/{domainId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the JS Snippet for this Domain.
     */
    async getSettingsForUserIdAndDomainId(requestParameters: SettingsApiGetSettingsForUserIdAndDomainIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSettingsForUserIdAndDomainIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the JS Snippet for this Domain, including appending to <head>.
     */
    async getSettingsForUserIdAndDomainIdJsRaw(requestParameters: SettingsApiGetSettingsForUserIdAndDomainIdJsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsForUserIdAndDomainIdJs().'
            );
        }

        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getSettingsForUserIdAndDomainIdJs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}/{domainId}/js`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the JS Snippet for this Domain, including appending to <head>.
     */
    async getSettingsForUserIdAndDomainIdJs(requestParameters: SettingsApiGetSettingsForUserIdAndDomainIdJsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSettingsForUserIdAndDomainIdJsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the JS Snippet for all domains of this User, including appending to <head>.
     */
    async getSettingsForUserIdJsRaw(requestParameters: SettingsApiGetSettingsForUserIdJsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsForUserIdJs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}/js`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get the JS Snippet for all domains of this User, including appending to <head>.
     */
    async getSettingsForUserIdJs(requestParameters: SettingsApiGetSettingsForUserIdJsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSettingsForUserIdJsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the Settings for this Domain.
     */
    async getSettingsJsonForDomainRaw(requestParameters: SettingsApiGetSettingsJsonForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Settings>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsJsonForDomain().'
            );
        }

        if (requestParameters['domainId'] == null) {
            throw new runtime.RequiredError(
                'domainId',
                'Required parameter "domainId" was null or undefined when calling getSettingsJsonForDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}/{domainId}/json`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters['domainId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsFromJSON(jsonValue));
    }

    /**
     * Get the Settings for this Domain.
     */
    async getSettingsJsonForDomain(requestParameters: SettingsApiGetSettingsJsonForDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Settings> {
        const response = await this.getSettingsJsonForDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the Settings for all domains of this User.
     */
    async getSettingsJsonForUserRaw(requestParameters: SettingsApiGetSettingsJsonForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Settings>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getSettingsJsonForUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/settings/{userId}/json`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingsFromJSON(jsonValue));
    }

    /**
     * Get the Settings for all domains of this User.
     */
    async getSettingsJsonForUser(requestParameters: SettingsApiGetSettingsJsonForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Settings> {
        const response = await this.getSettingsJsonForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
