/* tslint:disable */
/* eslint-disable */
/**
 * CallNowButton API
 * API for CallNowButton. Includes auth, domains, buttons/actions/conditions, but also chat and admin features.
 *
 * The version of the OpenAPI document: 0.0.183-local
 * Contact: support@nowbuttons.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Condition
 */
export interface Condition {
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Condition
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    conditionType: ConditionConditionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    filterType: ConditionFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    matchType: ConditionMatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Condition
     */
    matchValue: string;
}


/**
 * @export
 */
export const ConditionConditionTypeEnum = {
    Url: 'URL',
    Geo: 'GEO'
} as const;
export type ConditionConditionTypeEnum = typeof ConditionConditionTypeEnum[keyof typeof ConditionConditionTypeEnum];

/**
 * @export
 */
export const ConditionFilterTypeEnum = {
    Include: 'INCLUDE',
    Exclude: 'EXCLUDE'
} as const;
export type ConditionFilterTypeEnum = typeof ConditionFilterTypeEnum[keyof typeof ConditionFilterTypeEnum];

/**
 * @export
 */
export const ConditionMatchTypeEnum = {
    Simple: 'SIMPLE',
    Exact: 'EXACT',
    Regex: 'REGEX',
    Substring: 'SUBSTRING',
    CountryCode: 'COUNTRY_CODE'
} as const;
export type ConditionMatchTypeEnum = typeof ConditionMatchTypeEnum[keyof typeof ConditionMatchTypeEnum];


/**
 * Check if a given object implements the Condition interface.
 */
export function instanceOfCondition(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('user' in value)) return false;
    if (!('conditionType' in value)) return false;
    if (!('filterType' in value)) return false;
    if (!('matchType' in value)) return false;
    if (!('matchValue' in value)) return false;
    return true;
}

export function ConditionFromJSON(json: any): Condition {
    return ConditionFromJSONTyped(json, false);
}

export function ConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Condition {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'conditionType': json['conditionType'],
        'filterType': json['filterType'],
        'matchType': json['matchType'],
        'matchValue': json['matchValue'],
    };
}

export function ConditionToJSON(value?: Condition | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user': UserToJSON(value['user']),
        'conditionType': value['conditionType'],
        'filterType': value['filterType'],
        'matchType': value['matchType'],
        'matchValue': value['matchValue'],
    };
}

